<template>
    <div class="home">
        <div class="head content Between">
            <div class="fl flex-center">
                <img @click="toHome" class="logoImg pointer" :src="formData.headerLogoUrl" alt="" />
            </div>
            <div class="fr flex-center">
                <div class="icon_phone">
                    <img src="@/assets/img/homes/phone.png" alt="" />
                </div>
                <div>
                    <p class="service_hotline">全国服务热线</p>
                    <p class="phone">186-0302-9588</p>
                </div>
                <div class="links flex-center pointer">
                    <div class="links_div Between">
                        <div class="name">果尔佳站群</div>
                        <img src="@/assets/img/icon_x.png" alt="" />
                    </div>
                    <div class="child_div">
                        <a class="list_a flex-center" :href="item.stationGroupLink" target="_blank"
                            v-for="(item, index) in GejGroup" :key="index">
                            {{ item.stationGroupName }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="navBar" :class="isFiexd == true ? 'fixedHead' : ''">
            <div class="content Between">
                <div class="list display pointer" :class="navBarIndex == item.id ? 'navActive' : ''"
                    v-for="(item, index) in navBarList" :key="index" @click="changeBtn(item)"
                    @mouseenter="mouseEnter(item.id, index)" @mouseleave="classifyIndex = null">
                    {{ item.name }}
                    <div class="lines"></div>
                    <div v-if="navBarIndex == item.id" class="line"></div>
                    <div class="classify_box pointer" v-if="(classifyIndex == 1 && item.id == 2) ||
                    (classifyIndex == 2 && item.id == 3) ||
                    (classifyIndex == 3 && item.id == 4) ||
                    (classifyIndex == 4 && item.id == 5) ||
                    (classifyIndex == 5 && item.id == 6) ||
                    (classifyIndex == 6 && item.id == 7)
                    ">
                        <div class="classify_list" :class="classifyLevelIndex == indexs ? 'classifyActive' : ''"
                            @click.stop="jump(items, item.id, indexs)" v-for="(items, indexs) in classifyList"
                            :key="indexs" @mouseenter="mouseEnterClassify(items, indexs)">
                            {{ items.label }}
                            <img v-if="item.id == 3 && classifyLevelIndex == indexs" class="icon_jt"
                                src="@/assets/img/homes/jt.png" alt="" />
                        </div>
                    </div>
                    <div class="classify_boxs classify_box pointer" v-if="classifyIndex == 2 && item.id == 3 && solutionList.length != 0
                    ">
                        <div class="classify_list display" :class="solutionIndex == indexs_1 ? 'classifyActive' : ''"
                            v-for="(items_1, indexs_1) in solutionList" :key="indexs_1"
                            @mouseenter="mouseEnterClassifys(items_1, indexs_1)" @mouseleave="solutionIndex = null"
                            @click.stop="jump(items_1, item.id, indexs_1)">
                            {{ items_1.solutionName }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content_box">
            <router-view></router-view>
        </div>

        <!-- <div class="sidebar_right">
            <img class="icon_logo" src="@/assets/img/icon_logo.png" alt="" />
            <div class="sidebar_cont">
                <div class="sidebar_List display column pointer" v-for="(item, index) in sidebarData" :key="index"
                    @click="sidebarBtn">
                    <img :src="item.menuIconUrl" alt="" />
                    <div class="name">{{ item.menuName }}</div>
                    <div class="line"></div>
                </div>
                <div class="goTop display column pointer" @click="goTop" v-show="goTopShow">
                    <img src="@/assets/img/top.png" alt="" />
                    <div class="top_div">TOP</div>
                </div>
            </div>
        </div> -->
        <Footer :url="formData.iconUrl"></Footer>
        <div class="make_appointment" v-if="$store.state.isShow">
            <div class="mask"></div>
            <div class="make_appointment_content">
                <img class="icon_close pointer" @click="$store.state.isShow = false" src="@/assets/img/close.png"
                    alt="" />
                <div class="title display">免费预约</div>
                <div class="main">
                    <div class="list display">
                        <input type="text" placeholder="请输入公司名称" v-model="makeAppointment.companyName" />
                    </div>
                    <div class="list display">
                        <el-cascader size="large" :options="options" :props="props" v-model="AreaArray"
                            @change="selectArea" placeholder="请选择所在城市" clearable></el-cascader>
                    </div>
                    <div class="list display">
                        <el-select @change="selectType" v-model="makeAppointment.categoryName" clearable
                            placeholder="请选择需求类型">
                            <el-option v-for="item in $store.state.serviceType" :key="item.value" :label="item.label"
                                :value="item">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="list Between user_phone">
                        <input class="min_input" type="text" placeholder="请输入姓名" v-model="makeAppointment.userName" />
                        <input class="min_input" type="text" maxlength="11" placeholder="请输入手机号"
                            v-model="makeAppointment.phoneNumber" />
                    </div>
                    <div class="btn display pointer" @click="signUp">立即预约</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Footer from '../components/footer.vue'
import areaJSON from '@/assets/js/area.json'
export default {
    components: {
        Footer,
    },
    data() {
        return {
            isFiexd: false,
            scrollTop: '',
            options: areaJSON,
            AreaArray: [],
            props: {
                children: 'children',
                label: 'name',
                value: 'name',
            },
            navBarIndex: 1,
            classifyIndex: null,
            classifyLevelIndex: null,
            navBarList: [
                {
                    id: 1,
                    name: '首页',
                    url: '/',
                },
                {
                    id: 2,
                    name: '服务范围',
                    url: '/scopeServices',
                },
                {
                    id: 3,
                    name: '解决方案',
                    url: '/solution',
                },
                {
                    id: 4,
                    name: '工程案例',
                    url: '/projectCase',
                },
                {
                    id: 5,
                    name: '产品中心',
                    url: '/productCenter',
                },
                {
                    id: 6,
                    name: '新闻资讯',
                    url: '/news/index',
                },
                {
                    id: 7,
                    name: '关于我们',
                    url: '/aboutUs',
                },
                {
                    id: 8,
                    name: '联系我们',
                    url: '/contactUs',
                },
            ],
            classifyList: [],
            goTopShow: false,
            formData: {},
            solutionList: [],
            solutionIndex: null,
            fatherName: '',
            sidebarData: [],
            makeAppointment: {
                serviceAreaCategoryId: '',
                categoryName: '',
                userName: '',
                phoneNumber: '',
                companyName: '',
                province: '',
                city: '',
                district: '',
                detailAddress: 0,
                detailedRequirements: 0,
            },
            GejGroup: [],
        }
    },
    created() {
        if (this.$route.query.ids) {
            this.navBarIndex = this.$route.query.ids
        } else {
            this.navBarIndex = null
        }
        this.getData()
        this.getNavigationDat()
        this.getGejGroup()
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll)
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
        getGejGroup() {
            this.api.findGejStationGroup().then((res) => {
                this.GejGroup = res.data || []
            })
        },
        handleScroll() {
            this.scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop
            if (this.scrollTop > 100) {
                this.isFiexd = true
            } else {
                this.isFiexd = false
            }
            if (this.scrollTop > 500) {
                //设置页面滑动多少显示按钮
                this.goTopShow = true
            } else {
                this.goTopShow = false
            }
        },
        goTop() {
            let timer = null,
                _that = this
            cancelAnimationFrame(timer)
            timer = requestAnimationFrame(function fn() {
                if (_that.scrollTop > 0) {
                    _that.scrollTop -= 100 //回到顶部的速度
                    document.body.scrollTop = document.documentElement.scrollTop =
                        _that.scrollTop
                    timer = requestAnimationFrame(fn)
                } else {
                    cancelAnimationFrame(timer)
                    _that.goTopShow = false
                }
            })
        },

        toHome() {
            this.$router.push({
                path: '/',
                query: {
                    ids: 1,
                },
            })
            this.navBarIndex = 1
        },
        //点击导航
        changeBtn(item) {
            console.log(item)
            let classId = ''
            let className = ''
            let fatherName = ''
            this.navBarIndex = item.id
            if (item.id == 1) {
                className = '首页'
            } else if (item.id == 2) {
                classId = this.$store.state.serviceType[0].id
                className = this.$store.state.serviceType[0].label
            } else if (item.id == 3) {
                console.log(this.$store.state.solution[0].solutionVOS[0])
                classId = this.$store.state.solution[0].solutionVOS[0].id
                className = this.$store.state.solution[0].solutionVOS[0].solutionName
                fatherName = '新建筑'
            } else if (item.id == 4) {
                classId = this.$store.state.projectCase[0].id
                className = this.$store.state.projectCase[0].label
            } else if (item.id == 5) {
                classId = this.$store.state.goodsData[0].id
                className = this.$store.state.goodsData[0].label
            } else if (item.id == 6) {
                classId = this.$store.state.journalism[0].id
                className = this.$store.state.journalism[0].label
            } else if (item.id == 7) {
                classId = ''
                className = '公司简介'
            } else if (item.id == 8) {
                classId = ''
                className = '联系我们'
            }
            let obj = {
                ids: item.id,
                id: classId,
                name: className,
                index: 0,
                fatherName: fatherName,
                isReturn: 3,
            }
            this.$store.commit('getTypeId', classId)
            if (item.id != 1) {
                localStorage.setItem('navBarData', JSON.stringify(obj))
            }
            this.$router.push({
                path: item.url,
                query: {
                    // ids: item.id,
                    id: classId,
                    // name: className,
                    // index: 0,
                    // fatherName: fatherName,
                    // isReturn: 3,
                },
            })
        },
        //点击分类跳转
        jump(item, id, index) {
            console.log(item, id)
            if (item.label != '新建筑' && item.label != '旧建筑') {
                if (item.solutionVOS == undefined) {
                    this.navBarIndex = id
                    let url = ''
                    if (this.classifyIndex == 1) {
                        url = '/scopeServices'
                    } else if (this.classifyIndex == 2) {
                        url = '/solution'
                    } else if (this.classifyIndex == 3) {
                        url = '/projectCase'
                    } else if (this.classifyIndex == 4) {
                        url = '/productCenter'
                        localStorage.removeItem('isService')
                    } else if (this.classifyIndex == 5) {
                        url = '/news/index'
                    } else if (this.classifyIndex == 6) {
                        url = '/aboutUs'
                    }

                    let hrefUrl = window.location.href
                    if (this.classifyIndex == 1) {
                        if (hrefUrl.indexOf('/scopeServices') >= 0) {
                            this.openNo(url, item, id, index)
                        } else {
                            this.openYes(url, item, id, index)
                        }
                    } else if (this.classifyIndex == 2) {
                        if (hrefUrl.indexOf('/solution') >= 0) {
                            this.openNo(url, item, id, index)
                        } else {
                            this.openYes(url, item, id, index)
                        }
                    } else {
                        this.openNo(url, item, id, index)
                    }
                }
                let obj = {
                    ids: id,
                    id: item.id,
                    name: item.label ? item.label : item.solutionName,
                    index: index,
                    fatherName: this.fatherName,
                    isReturn: 3,
                }
                this.$store.commit('getTypeId', item.id)
                localStorage.setItem('navBarData', JSON.stringify(obj))
            }
        },
        openYes(url, item, id, index) {
            let routeData = this.$router.resolve({
                path: url,
                query: {
                    // ids: id,
                    id: item.id,
                    // name: item.label ? item.label : item.solutionName,
                    // index: index,
                    // fatherName: this.fatherName,
                    // isReturn: 3,
                },
            })
            window.open(routeData.href, '_blank')
        },
        openNo(url, item, id, index) {
            this.$router.push({
                path: url,
                query: {
                    // ids: id,
                    id: item.id,
                    // name: item.label ? item.label : item.solutionName,
                    // index: index,
                    // fatherName: this.fatherName,
                    // isReturn: 3,
                },
            })
        },
        //导航移入
        mouseEnter(id, index) {
            this.classifyIndex = index
            // this.navBarIndex = id
            if (this.classifyIndex == 1) {
                this.classifyList = this.$store.state.serviceType
            } else if (this.classifyIndex == 2) {
                this.classifyList = this.$store.state.solution
            } else if (this.classifyIndex == 3) {
                this.classifyList = this.$store.state.projectCase
            } else if (this.classifyIndex == 4) {
                this.classifyList = this.$store.state.goodsData
            } else if (this.classifyIndex == 5) {
                this.classifyList = this.$store.state.journalism
            } else if (this.classifyIndex == 6) {
                this.classifyList = this.$store.state.aboutUsList
            }
        },
        // 分类移入
        mouseEnterClassify(item, index) {
            if (item.solutionVOS) {
                this.fatherName = item.solutionName
                this.solutionList = item.solutionVOS
            } else {
                this.fatherName = ''
            }
            this.classifyLevelIndex = index
        },
        // 分类移入
        mouseEnterClassifys(item, index) {
            this.solutionIndex = index
        },
        //获取logo
        getData() {
            this.api.findPlatformInfo().then((res) => {
                this.formData = res.data
            })
        },
        // 获取侧边导航
        getNavigationDat() {
            this.api.findNavigationData().then((res) => {
                this.sidebarData = res.data
            })
        },
        sidebarBtn() {
            //联系客服
            // document.getElementById('layui-kflayer19911116').style.display = 'block'
            document.getElementById('nb-frame-chat-box').style.display = 'block'

        },
        // 立即报名
        signUp() {
            if (this.makeAppointment.companyName == '') {
                return this.$message.error('请输入公司名称')
            }
            if (this.makeAppointment.province == '') {
                return this.$message.error('请选择所在城市')
            }
            if (this.makeAppointment.categoryName == '') {
                return this.$message.error('请选择需求类型')
            }
            if (this.makeAppointment.userName == '') {
                return this.$message.error('请输入姓名')
            }
            if (!/^[0-9]{11}$/.test(this.makeAppointment.phoneNumber)) {
                return this.$message.error('请正确输入手机号')
            }
            this.api.addReserve(this.makeAppointment).then((res) => {
                if (res.code == 0) {
                    this.$message.success('提交成功')
                    this.makeAppointment = {
                        serviceAreaCategoryId: '',
                        categoryName: '',
                        userName: '',
                        phoneNumber: '',
                        companyName: '',
                        province: '',
                        city: '',
                        district: '',
                        detailAddress: 0,
                        detailedRequirements: 0,
                    }
                    this.AreaArray = []
                    this.$store.state.isShow = false
                }
            })
        },
        //选择服务呢类型分类
        selectType(val) {
            console.log(val)
            this.makeAppointment.categoryName = val.label
            this.makeAppointment.serviceAreaCategoryId = val.value
        },
        // 选择省市区
        selectArea(val) {
            this.makeAppointment.province = val[0]
            this.makeAppointment.city = val[1]
            this.makeAppointment.district = val[2]
        },
    },
}
</script>

<style lang="scss" scoped>
.home {
    .head {
        height: 100px;

        .fl {
            .logoImg {
                width: 530px;
                height: 80px;
            }
        }

        .fr {
            .icon_phone {
                margin-right: 12px;

                img {
                    width: 47px;
                    height: 47px;
                }
            }

            .service_hotline {
                font-size: 16px;
                color: #666666;
                line-height: 16px;
                margin-bottom: 8px;
            }

            .phone {
                font-size: 30px;
                font-family: Arial;
                font-weight: bold;
                line-height: 30px;
                color: #e62129;
            }

            .links:hover {
                .child_div {
                    opacity: 1;
                    visibility: visible;
                    transition: opacity 0.5s;
                    animation: none;
                }
            }

            .links {
                width: 240px;
                height: 40px;
                background: #ffffff;
                border: 1px solid #dddddd;
                position: relative;
                margin-left: 20px;

                .links_div {
                    width: 100%;
                    height: 100%;
                    padding: 0 18px;
                    box-sizing: border-box;

                    .name {
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #666666;

                        img {
                            margin-top: 5px;
                        }
                    }
                }

                .child_div {
                    width: 240px;
                    position: absolute;
                    top: 38px;
                    left: -1px;
                    background: #ffffff;
                    border: 1px solid #ddd;
                    border-bottom: 0;
                    z-index: 9999;
                    opacity: 0;
                    visibility: hidden;
                    transition: opacity 0.3s 0.3s, visibility 0.3s;
                    animation: control-pointer 0.3s;

                    .list_a {
                        width: 238px;
                        height: 42px;
                        padding-left: 18px;
                        box-sizing: border-box;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #666666;
                        border-bottom: 1px solid #dddddd;
                    }

                    .list_a:hover {
                        background: #e62129;
                        color: #fff;
                    }
                }
            }
        }
    }

    .navBar {
        width: 100%;
        height: 58px;
        background: url('../assets/img/homes/navImg.png') no-repeat;
        background-size: 100% 100%;
        padding-left: 8px;

        .list:hover {
            color: #e62129;

            .lines {
                width: 100%;
                height: 3px;
                position: absolute;
                left: 0;
                bottom: 0px;
                background: #e62129;
            }
        }

        .list {
            height: 58px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            position: relative;

            img {
                width: 23px;
                height: 15px;
                margin-right: 11px;
            }

            .line {
                width: 100%;
                height: 3px;
                position: absolute;
                left: 0;
                bottom: 0px;
                background: #e62129;
            }

            .classify_box::-webkit-scrollbar {
                display: none;
            }

            .classify_box {
                width: 200px;
                background: #fff;
                opacity: 0.9;
                position: absolute;
                top: 58px;
                left: 50%;
                margin-left: -100px;
                padding: 15px 0px;
                overflow: hidden;
                overflow-y: scroll;
                z-index: 77;

                .classify_list {
                    width: 100%;
                    height: 35px;
                    color: #333333;
                    font-size: 16px;
                    position: relative;
                    padding-left: 20px;
                    box-sizing: border-box;

                    .icon_jt {
                        width: 7px;
                        height: 12px;
                        margin-left: 12px;
                        position: absolute;
                        top: 13px;
                        left: 130px;
                    }
                }

                .classifyActive {
                    color: #e62129 !important;
                }
            }

            .classify_boxs {
                margin-left: 100px;
            }
        }

        .navActive {
            color: #e62129;
        }
    }

    .navBar .list:last-child .line {
        // display: none;
    }
}

@media screen and (max-width: 1380px) {
    .sidebar_right {
        position: fixed;
        top: 40%;
        right: 0px;
        margin-top: -200px;
        z-index: 9;

        .icon_logo {
            width: 60px;
        }

        .sidebar_cont {
            width: 60px;
            border: 1px solid #e62129;
            margin-top: -5px;
            background: #fff;

            .sidebar_List:hover {
                .name {
                    color: #e62129 !important;
                }
            }

            .sidebar_List {
                width: 100%;
                height: 50px;
                position: relative;

                img {
                    width: 20px;
                    height: 20px;
                }

                .name {
                    font-size: 12px;
                    color: #333333;
                    margin-top: 2px;
                }

                .line {
                    width: 60px;
                    height: 1px;
                    background: #d4d4d4;
                    position: absolute;
                    left: 50px;
                    bottom: 0;
                    margin-left: -43px;
                }
            }

            .sidebar_List:last-child {
                .line {
                    display: none;
                }
            }
        }

        .goTop {
            margin: 10px 0;

            img {
                width: 13px;
                height: 8px;
            }

            .top_div {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #e62129;
                margin-top: 4px;
            }
        }
    }

    .make_appointment_content {
        width: 400px;
        height: 360px;
        background: #ffffff;
        border-radius: 20px;
        position: fixed;
        top: 50%;
        left: 50%;
        margin-top: -200px;
        margin-left: -190px;
        z-index: 1001;

        .icon_close {
            width: 32px;
            height: 32px;
            background: white;
            position: absolute;
            top: -8px;
            right: -8px;
            border-radius: 50%;
        }

        .title {
            width: 400px;
            height: 50px;
            border-radius: 20px 20px 0 0;
            background: #e62129;
            font-size: 22px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
        }

        .main {
            width: 400px;
            padding: 20px;
            box-sizing: border-box;

            .user_phone {}

            .list {
                width: 360px;
                height: 40px;
                margin-bottom: 14px;

                input {
                    width: 360px;
                    height: 40px;
                    background: #f5f5f5;
                    font-size: 14px;
                    padding-left: 16px;
                }

                .min_input {
                    width: 160px;
                    height: 40px;
                    background: #f5f5f5;
                    font-size: 14px;
                    padding-left: 16px;
                }

                ::v-deep.el-input__inner {
                    width: 360px;
                    height: 40px;
                    background: #f5f5f5;
                    border: none;
                    border-radius: 0;
                }
            }

            .btn {
                width: 360px;
                height: 40px;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                background: #e62129;
            }
        }
    }
}

@media screen and (min-width: 1380px) {
    .sidebar_right {
        // position: fixed;
        // top: 40%;
        // left: 50%;
        // margin-top: -150px;
        // margin-left :700px;
        // z-index: 9;
        position: fixed;
        top: 40%;
        right: 50px;
        margin-top: -200px;
        z-index: 9;

        .icon_logo {
            width: 80px;
        }

        .sidebar_cont {
            width: 80px;
            border: 1px solid #e62129;
            margin-top: -5px;
            background: #fff;

            .sidebar_List:hover {
                .name {
                    color: #e62129 !important;
                }
            }

            .sidebar_List {
                width: 100%;
                height: 60px;
                position: relative;

                img {
                    width: 24px;
                    height: 24px;
                }

                .name {
                    font-size: 14px;
                    color: #333333;
                    margin-top: 2px;
                }

                .line {
                    width: 65px;
                    height: 1px;
                    background: #d4d4d4;
                    position: absolute;
                    left: 50px;
                    bottom: 0;
                    margin-left: -43px;
                }
            }

            .sidebar_List:last-child {
                .line {
                    display: none;
                }
            }
        }

        .goTop {
            margin: 10px 0;

            img {
                width: 13px;
                height: 8px;
            }

            .top_div {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #e62129;
                margin-top: 4px;
            }
        }
    }

    .make_appointment_content {
        width: 500px;
        height: 432px;
        background: #ffffff;
        border-radius: 20px;
        position: fixed;
        top: 50%;
        left: 50%;
        margin-top: -250px;
        margin-left: -216px;
        z-index: 1001;

        .icon_close {
            width: 44px;
            height: 44px;
            background: white;
            position: absolute;
            top: -10px;
            right: -10px;
            border-radius: 50%;
        }

        .title {
            width: 500px;
            height: 62px;
            border-radius: 20px 20px 0 0;
            background: #e62129;
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
        }

        .main {
            width: 369px;
            padding: 30px;
            box-sizing: border-box;

            .user_phone {}

            .list {
                width: 437px;
                height: 46px;
                margin-bottom: 18px;

                input {
                    width: 430px;
                    height: 44px;
                    background: #f5f5f5;
                    font-size: 16px;
                    padding-left: 20px;
                }

                .min_input {
                    width: 192px;
                    height: 44px;
                    background: #f5f5f5;
                    font-size: 16px;
                    padding-left: 20px;
                }

                ::v-deep.el-input__inner {
                    width: 436px;
                    height: 44px;
                    background: #f5f5f5;
                    border: none;
                    border-radius: 0;
                }
            }

            .btn {
                width: 437px;
                height: 50px;
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                background: #e62129;
            }
        }
    }
}

.content_box {
    min-height: 1000px;
}

.fixedHead {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 888;
}
</style>